jQuery(document).ready(function ($) {
	cookie();
	$(window).on('scroll', scroll_cookie).trigger('scroll');
});

function cookie() {
	$('.cookies .close').on('click', function() {
		Cookies.set('allow_cookies', 1, { expires: 365 * 10 });
		$(this).closest('.cookies').slideUp('fast', function() {
			set_margin();
			$(this).remove();
		});
	});
}
function scroll_cookie() {
	var $cookies = $('.cookies');
	if($(window).scrollTop() == 0) {
		$cookies.slideDown({
			easing:	'linear',
			duration: 'fast',
			complete: set_margin
		});
	}
	else if($cookies.css('display') != 'none') {
		$cookies.slideUp({
			easing:	'linear',
			duration: 'fast',
			complete: set_margin
		});
	}
}

function set_margin() {
	$('.main-wrapper').css('margin-top', $('#container-header').height() + 'px');
}